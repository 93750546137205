import photo from "../../assets/catalog/photo_2024-01-23_12-01-31.jpg";
import photo1 from "../../assets/catalog/photo_2024-01-22_21-27-36.jpg";
import photo3 from "../../assets/catalog/photo_2024-01-23_12-01-36.jpg";
import photo4 from "../../assets/catalog/photo_2024-01-23_12-01-46.jpg";
import photo5 from "../../assets/catalog/photo_2024-01-23_12-01-49.jpg";
import photo7 from "../../assets/catalog/photo_2024-01-23_12-05-24.jpg";
import photo8 from "../../assets/catalog/photo_2024-01-23_12-05-30.jpg";
import photo11 from "../../assets/catalog/photo_2024-01-23_12-05-33.jpg";
import photo12 from "../../assets/catalog/photo_2024-02-01_22-43-06_(1)-transformed (1).jpeg"
import photo13 from "../../assets/catalog/photo_2024-05-26_15-42-51.jpg"
import photo14 from "../../assets/catalog/photo_2024-05-26_15-27-19.jpg"
import photo15 from "../../assets/catalog/photo_2024-05-26_15-27-14.jpg"
import photo16 from "../../assets/catalog/photo_2024-05-26_15-26-41.jpg"



export const data = [
  photo,
  photo3,
  photo4,
  photo5,
  photo7,
  photo8,
  photo1,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16
];
