export const data1 = [
  "Фундамент – ж/б свайно-ленточный.",
  "Стены - газоблок , облицовка кирпич/штукатурка.",
  "Перегородки - блок пазогребневый.",
  "Кровля – металлочерепица.",
  "Окна - стеклопакеты ПВХ.",
  "Вентиляция - естественная вытяжная.",
  "Электроснабжение – мощность 15 кВт, класс напряжения 0,4 Кв.",
  "Водоснабжение и канализация - скважина, выгреб из ж/б колец.",
  "Отопление - водяные теплые полы.",
  "Газификация - по проекту догазификации.",
  "Чистовая отделка.",
];

export const data2 = [
  "Используем Ваш проект.",
  "Поможем выбрать Типовой проект, ориентируясь на готовые объекты."
];

export const data3 = [
  "Капитальный ремонт.",
  "Электромонтажные работы.",
  "Сантехнические работы.",
  "Отделочные работы любого уровня."
]